import React from 'react'
import './styles.scss'
import { StaticQuery, graphql } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import Glitch from 'components/glitch'
import Typewriter from 'typewriter-effect'
import ThemeContext from '../../context'
import CV from './../../awais/AwaisRafiq_CV.pdf';

class Hero extends React.Component {

  static contextType = ThemeContext

  techIcons = () => {
    let icons = [];

    for(let i=1; i<9; i++) {
      icons.push(
        <img src={require(`./../../../content/images/icons/${i}.png`)} alt="" height='40' />
      )
    }

    return icons;
  }

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="hero"
        style={{ height: this.context.height }}
      >
        <Row>
          <Col md={6} className="content">
            <div className="content-text">
              <div className="line-text">
                <h4>Hello, I'm</h4>
              </div>
              <Glitch text="Awais Rafiq" />
              <Typewriter
                options={{
                  strings: [
                    'Web Developer',
                    'Mobile App Developer'
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
              <button className="hover-button">
                <a className='cv-text' href = {CV} target = "_blank" className=''> <span>Download CV</span></a> 
              </button>
            </div>
            {/* {this.icons()} */}
            <div className='tech-icons'>
              {/* {this.techIcons()} */}
            </div>
          </Col>
          <Col md={6} className="img">
            <img
              src={require('./../../awais/picture.png')}
              alt="person"
              className='prof-picture'
              style={{ height: '100%' }}
            />
          </Col>
        </Row>
      </section>
    )
  }

  icons() {
    return this.props.icons.edges.map((value, index) => {
      return (
        <img
          src={value.node.childImageSharp.fluid.src}
          className={`animated fadeIn move-${
            Math.floor(Math.random() * 9) % 2 === 0 ? 'up' : 'down'
          } float-image`}
          style={{
            left: `${index * 9 + 10}%`,
            bottom: `${Math.random() *
              (+(index % 2 === 0 ? 80 : 16) - +(index % 2 === 0 ? 77 : 13)) +
              +(index % 2 === 0 ? 77 : 13)}%`,
          }}
          alt="shape"
          key={index}
        />
      )
    })
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        icons: allFile(
          filter: {
            extension: { regex: "/(png)/" }
            relativeDirectory: { eq: "icons" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
        Img: file(relativePath: { eq: "person.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              src
            }
          }
        }
      }
    `}
    render={({ icons, Img }) => <Hero icons={icons} mainImg={Img} {...props} />}
  />
)
